<template>
    <div style="position:relative">
        <p v-if="hintText">{{ hintText }}</p>
        <v-datetime-picker
            ref="datepicker"
            :label="$t('fields.datetime.label')"
            v-model="datetime"
            :text-field-props="textFieldProps"
            time-format="HH:mm:ss"
            :date-picker-props="datePickerProps"
            :time-picker-props="pickerProps"

        ></v-datetime-picker>
        <div class="icon-click" style="position:absolute;right:0;top:0;bottom:0;width:25px;height:50px;z-index:9999;"
             @click="triggerClick"></div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {getTime, fromUnixTime} from 'date-fns';
import format from 'date-fns/format';

export default {
    name: 'DateTime',
    props: {
        questionId: {},
        hintText: {
            type: String
        },
        data: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    data() {
        const allowFuture = typeof this.data?.allowFuture === 'undefined' ? true : this.data?.allowFuture;
        const allowPast = typeof this.data?.allowPast === 'undefined' ? true : this.data?.allowPast;

        return {
            allowPast: allowPast,
            allowFuture: allowFuture,
            datetime: null,
            textFieldProps: {
                appendIcon: 'event'
            }
        };
    },
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers', 'surveyMode']),
        pickerProps() {
            return {noTitle: this.screen$.height < 480};
        },
        datePickerProps() {
            return {...this.pickerProps, min: this.minDate, max: this.maxDate};
        },
        minDate() {
            if (this.allowPast) {
                return null;
            }
            return format(new Date(), 'yyyy-MM-dd');
        },
        maxDate() {
            if (this.allowFuture) {
                return null;
            }
            return format(new Date(), 'yyyy-MM-dd');
        },
    },
    watch: {
        datetime: function(val, oldVal) {
            const getTimeParsed = getTime(val);
            this.updateAnswer((getTimeParsed / 1000));
        }
    },
    methods: {
        triggerClick() {
            this.$refs.datepicker.display = true;
        },
        updateAnswer(date) {
            if (!date) {
                date = Date.now();
            }
            this.$emit('updateAnswer', date);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        const date = answer[this.questionId];
                        const date2 = fromUnixTime(date);
                        this.datetime = new Date(date2);
                    }
                });
            }
        }
    },
    created() {
        if (this.surveyMode === 'resume-survey') {
            this.checkForAnswer();
        }
    }
};
</script>
